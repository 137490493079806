/* AddTask-Specific Styles */

.task-item span:contains("{{deadline.toLocaleDateString()}}") {
  display: none;
}

/* Container styling */
.add-task-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    padding: 3rem 4rem;
    font-family: 'Poppins', sans-serif;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-top: 12rem;
    width: 90%;
    max-width: 800px;
    border: 2px solid #B0B0B0;
  }
  
  /* Label styling */
  .add-task-label {
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
    color: #7D7D7D;
    text-align: center;
  }
  
  /* Input field styling */
  .add-task-input {
    width: 90%;
    margin-bottom: 2rem;
    padding: 1.2rem;
    font-size: 1.5rem;
    text-align: center;
    border-radius: 8px;
    font-family: 'Montserrat', sans-serif;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #B0B0B0;
    transition: all 0.3s ease;
  }
  
  .add-task-input:focus {
    outline: none;
    border-color: #FFFFFF;
    box-shadow: 0 0 6px rgba(76, 175, 80, 0.2);
  }
  
  /* Rating container styling */
  .add-task-rating {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 1.8rem;
  }
  
  /* Button styling */
  .add-task-button {
    padding: 1.5rem;
    background-color: #2ECC71;
    color: white;
    font-size: 1.5rem;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 100%;
    margin-top: 2.2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .add-task-button:hover {
    background-color: #388E3C;
    transform: scale(1.05);
  }
  
  /* Specific button variations */
  .add-task-submit {
    background-color: #2ECC71;
  }
  
  .add-task-navigate {
    background-color: #FFA500;
  }
  
  .add-task-navigate:hover {
    background-color: #FF8C00;
  }
  
  /* Date Picker styling */
  .add-task-deadline-picker {
    font-size: 1rem;
    transition: background-color 0.3s;
  }

  /* Pipe Styling */
.add-task-pipe {
  width: 150px;
  cursor: pointer;
  margin-top: 20px;
    transition: transform 0.3s ease, filter 0.3s ease;
  }
  
  .add-task-pipe:hover {
    transform: scale(1.1);
    filter: brightness(1.2);
  }
  
  .add-task-pipe:active {
    transform: scale(0.9);
    filter: brightness(0.9);
  }

  .emoji-display {
    position: absolute;
    top: 47rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 3rem;
    animation: fallIntoPipe 2s ease-in-out;
  }

  .date-picker-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the date input horizontally */
    margin-top: 10px;
  }
  
  .date-picker-container label {
    font-family: 'Raleway', sans-serif; /* Change font */
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .date-picker {
    font-family: 'Poppins', sans-serif; /* Customize the date font */
    font-size: 1rem;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center; /* Centers the date text inside the input */
    width: 180px;
    margin-top: 5px;
  }
  
  .date-picker:focus {
    outline: none;
    border-color: #32cd32; /* Green border when focused */
  }
  
  @keyframes fallIntoPipe {
    0% {
      transform: translateX(-50%) translateY(-100%);
      opacity: 0;
    }
    50% {
      transform: translateX(-50%) translateY(50%);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(100%);
      opacity: 0;
    }
  }