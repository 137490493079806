.info-icon-container {
  position: absolute;
align-items: center;}

.info-icon {
  font-size: 10rem;
  cursor: pointer;
  color: #FF7043; /* Bright orange for visibility */
  font-weight: bold;
  transition: color 0.3s ease; /* Smooth color change on hover */
  align-items: center;
}

.info-icon:hover {
  color: #E65100; /* Darker orange on hover for better interactivity */
}

.tooltip {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #ffffff;
  color: #333;
  padding: 15px;
  width: 250px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
  font-size: 0.95rem;
  line-height: 1.6;
  text-align: center;
  z-index: 1000;
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth appearance/disappearance */
  opacity: 1; /* Fully visible */
}

.tooltip.hidden {
  transform: scale(0.95); /* Slightly smaller */
  opacity: 0; /* Invisible */
}

* Create Account Button */
.create-account-button {
  background-color: #4caf50;
  color: #ffffff;
  padding: 10px 15px;
  border-radius: 25px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
}

.email-input-container {
  width: 260px; /* Fixed width for consistent layout */
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.email-input-container.hidden {
  transform: scale(0.95); /* Slightly smaller */
  opacity: 0; /* Invisible */
}

.email-input {
  width: 100%; /* Fills the container */
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 1rem;
  box-sizing: border-box; /* Includes padding in width */
  margin-top: 10px; /* Corrected margin-top value */
}

.signup-button {
  width: 100%; /* Matches the width of the email input */
  padding: 10px;
  margin-top: 10px;
  background-color: #4CAF50;
  color: white;
  border: none; /* Removed unnecessary border */
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth background change */
}

.signup-button:hover {
  background-color: #45a049; /* Slightly darker green on hover */
}

.signup-message {
  margin-top: 10px;
  color: #4CAF50;
  font-weight: bold;
}

/* Info Icon Container */
.info-icon-container {
  position: relative;
}

/* Info Icon */
.info-icon {
  font-size: 2rem;
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10px;
  color: #333;
}

/* Tooltip */
.tooltip {
  position: absolute;
  top: 50px;
  right: 10px;
  background-color: #ffffff;
  color: #333;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}


/* Email Input Container */
.email-input-container {
  position: relative;
  top: 900px; /* Ajuste la hauteur verticale du conteneur */
  margin: 0 auto; /* Centre horizontalement */
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  text-align: center;
  max-width: 400px; /* Largeur maximale */
  width: 100%; /* S'adapte à l'écran */
}

/* Email Input */
.email-input {
  position: relative; /* Garde le positionnement normal */
  margin-top: 20px; /* Ajoute un espace léger au-dessus */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  width: 100%; /* Occupe toute la largeur disponible dans le conteneur */
  max-width: 300px; /* Limite la largeur maximale */
  box-sizing: border-box; /* Inclut le padding dans la largeur totale */
  margin-bottom: 15px; /* Espacement avec les éléments suivants */
}

/* Signup Button */
.signup-button {
  background-color: #4caf50;
  color: #ffffff;
  padding: 10px 15px;
  border-radius: 25px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
}

/* Signup Message */
.signup-message {
  margin-top: 10px;
}

.signup-message.success {
  color: green;
}

.signup-message.error {
  color: red;
}