/* Animations */
@keyframes pipe-slide-up {
  0% { transform: scale(1) translateY(100%); opacity: 0; }
  50% { transform: scale(0.9) translateY(-10%); opacity: 0.5; }
  100% { transform: scale(1) translateY(0); opacity: 1; }
}

@keyframes pipe-slide-down {
  0% { transform: translateY(0); opacity: 1; }
  50% { transform: translateY(50px); opacity: 0; }
  100% { transform: translateY(100vh); opacity: 0; }
}


@keyframes fallToPipe {
  0% { opacity: 1; transform: translateY(0) translateX(-50%); }
  100% { opacity: 0; transform: translateY(150px) translateX(-50%) scale(0.3); }
}

/* General Styling */
body {
  font-family: 'Poppins', sans-serif;
  background-color: white;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100vh;
}

/* Container Styling */
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10rem; /* Space for the logo */
}

.logo {
  margin-top: 1rem;
  width: 520px;
  height: auto;
  position: absolute;
  top: -3rem;
  left: 500px;
  filter: brightness(1.2) contrast(1.1);
  color: #4A4A4A;
}

/* Task Form Styling */
.task-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
  padding: 3rem 4rem;
  font-family: 'Poppins', sans-serif;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 12rem;
  width: 90%;
  max-width: 800px;
  border: 2px solid #B0B0B0;
}

.task-form label {
  font-size: 1.6rem;
  margin-bottom: 1.2rem;
  color: #7D7D7D;
  text-align: center;
}

.task-form input[type="text"],
.task-form select {
  width: 90%;
  margin-bottom: 2rem;
  padding: 1.2rem;
  font-size: 1.5rem;
  text-align: center;
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #B0B0B0;
  transition: all 0.3s ease;
}

.task-form input[type="text"]:focus,
.task-form select:focus {
  outline: none;
  border-color: #FFFFFF;
}

/* Stars Rating Styling */
.stars-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 1.8rem;
}

.star {
  font-size: 2.5rem;
  cursor: pointer;
  transition: color 0.2s ease, transform 0.2s ease;
}

.filled { color: #FFD700; }
.empty { color: #B0B0B0; }

.star:hover,
.star.selected {
  transform: scale(1.3);
  color: #FFD700;
}

/* Button Styling */
.task-form button {
  padding: 1.5rem;
  background-color: #2ECC71;
  color: white;
  font-size: 1.5rem;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;
  margin-top: 2.2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.task-form button:hover {
  background-color: #388E3C;
  transform: scale(1.05);
}

.button-feedback { background-color: #FFA500 !important; }

/* Matrix Container Styling */
.matrix-quadrants {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  padding: 2rem;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

/* Quadrant Styling */
.quadrant {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0.95;
}

.quadrant:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
}

/* Quadrant Colors with Fade */
.quadrant-important-urgent {
  background-color: rgba(255, 82, 82, 0.1); /* Light Red */
  border-left: 6px solid rgba(255, 82, 82, 0.8);
  animation: fadeIn 1.2s ease;
}

.quadrant-important-non-urgent {
  background-color: rgba(76, 175, 80, 0.1); /* Light Green */
  border-left: 6px solid rgba(76, 175, 80, 0.8);
  animation: fadeIn 1.2s ease;
}

.quadrant-non-important-urgent {
  background-color: rgba(255, 152, 0, 0.1); /* Light Orange */
  border-left: 6px solid rgba(255, 152, 0.8);
  animation: fadeIn 1.2s ease;
}

.quadrant-non-important-non-urgent {
  background-color: rgba(158, 158, 158, 0.1); /* Light Grey */
  border-left: 6px solid rgba(158, 158, 158, 0.8);
  animation: fadeIn 1.2s ease;
}

/* Fade In Animation */
@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(-10px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Task Styling */
.task-item {
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 12px 16px;
  margin: 12px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.task-item:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Completed Task Styling */
.task-item.completed p {
  text-decoration: line-through;
  color: #888;
}

/* Styling for the floating green tick */
.complete-button {
  font-size: 1.8rem;
  background: none;
  border: none;
  color: #4caf50;
  cursor: pointer;
  transition: transform 0.2s ease, color 0.3s ease;
  margin-left: 10px;
}

.complete-button:hover {
  transform: scale(1.1);
  color: #388e3c;
}

/* Styling for delete button (appears when task is completed) */
.delete-button {
  font-size: 1.8rem;
  background: none;
  border: none;
  color: #ff5252;
  cursor: pointer;
  transition: transform 0.2s ease, color 0.3s ease;
  margin-left: 10px;
}

.delete-button:hover {
  transform: scale(1.1);
  color: #d32f2f;
}

/* Scroll Arrows */
.down-arrow, .up-arrow {
  font-size: 3rem;
  text-align: center;
  margin: 2rem;
  cursor: pointer;
}

/* Pipe Icon Styling */
.pipe-icon {
  width: 10rem;
  transition: transform 0.3s ease;
  margin-top: 4rem;
}

.pipe-icon.down { transform: rotate(0deg); }
.pipe-icon.up, .pipe-icon.rotate { transform: rotate(180deg); }

.pipe-icon:hover { transform: scale(1.2); }

/* Falling Emoji Animation */
.falling-emoji {
  position: absolute;
  top: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem;
  opacity: 1;
  animation: fallToPipe 1.5s ease-in forwards;
}

/* Lock Page Scrolling */
.lock-page {
  overflow: hidden;
  height: 100vh;
}

/* Icon styling */
.info-icon-container {
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
}

.info-icon {
  position: absolute;
  left: -15rem;
  width: 4rem;
  font-size: 2rem;
  cursor: pointer;
}

/* Tooltip styling */
.tooltip {
  position: absolute;
  top: 1.5rem;
  left: -10rem;
  width: 12.5rem;
  padding: 0.625rem;
  background-color: #333;
  color: white;
  border-radius: 0.3125rem;
  font-size: 0.9rem;
  text-align: left;
  z-index: 1000;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}

.tooltip a {
  color: #4CAF50;
  text-decoration: underline;
}

/* Override the header background color */
.custom-flatpickr .flatpickr-months {
  background-color: #4CAF50 !important; /* Use the green you want */
  color: white !important;
}

/* Override the weekday bar background and text color */
.custom-flatpickr .flatpickr-weekdaycontainer {
  background-color: #FFA500 !important; /* Orange for the weekday bar */
}

.custom-flatpickr .flatpickr-weekday {
  color: white !important;
  font-weight: bold !important;
}

/* Override month/year input background to blend with the header */
.custom-flatpickr .flatpickr-current-month .flatpickr-monthDropdown-months,
.custom-flatpickr .flatpickr-current-month .numInput {
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: white !important;
  border: none !important;
}

/* Override selected date color */
.custom-flatpickr .flatpickr-day.selected,
.custom-flatpickr .flatpickr-day.startRange,
.custom-flatpickr .flatpickr-day.endRange,
.custom-flatpickr .flatpickr-day.selected.inRange,
.custom-flatpickr .flatpickr-day.today {
  background-color: #4CAF50 !important; /* Green for selected day */
  color: white !important;
}

/* Override month navigation arrow colors */
.custom-flatpickr .flatpickr-prev-month,
.custom-flatpickr .flatpickr-next-month {
  color: #FFA500 !important; /* Orange arrows */
}

/* Remove any other conflicting styles */
.custom-flatpickr .flatpickr-current-month input {
  box-shadow: none !important;
}

/* Force calendar to appear below the input */
.custom-flatpickr .flatpickr-calendar {
  top: 100% !important; /* Position calendar directly below the input */
  left: 0 !important;   /* Align it to the left edge of the input */
  margin-top: 5px;      /* Small spacing between input and calendar */
}

/* Optional: Adjust width if needed */
.custom-flatpickr .flatpickr-calendar {
  width: auto;
  min-width: 250px; /* Adjust width as desired */
}