.matrix-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: relative;
}

.matrix {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  width: 90%;
  max-width: 1200px;
  box-sizing: border-box;
}

/* Dynamic Borders */
.red-border {
  border: 4px solid red;
}

.green-border {
  border: 4px solid green;
}

.orange-border {
  border: 4px solid orange;
}

.gray-border {
  border: 4px solid gray;
}

/* Quadrant Styling */
.quadrant {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.quadrant:hover {
  transform: scale(1.03);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

.quadrant-header {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.quadrant-header:hover::after {
  content: attr(title);
  display: block;
  position: absolute;
  top: -30px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
}


.task-item.completed {
  text-decoration: line-through;
  color: gray;
}

.no-tasks {
  text-align: center;
  font-size: 1rem;
  color: #aaa;
}

.task-done-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}

.task-delete-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}

/* General quadrant styling */
.quadrant {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align tasks to the top */
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  min-height: 250px; /* Ensure consistent quadrant height */
}

.quadrant:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.quadrant h3 {
  font-size: 1.8rem;
  font-family: 'Raleway', sans-serif;
  margin-bottom: 10px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
}

/* Placeholder text for empty quadrants */
.placeholder {
  color: #aaa;
  font-style: italic;
  text-align: center;
  margin-top: 10px;
}

/* Task Item Styling */
.task-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, background-color 0.2s ease;
  width: 100%; /* Stretch task across quadrant */
}

.task-item.completed {
  text-decoration: line-through;
  opacity: 0.6;
  background-color: rgba(200, 200, 200, 0.5);
}

/* Buttons for tasks */
.task-done-button,
.task-delete-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-left: 10px;
  transition: transform 0.2s, color 0.2s;
}

.task-done-button:hover {
  transform: scale(1.2);
  color: green;
}

.task-delete-button:hover {
  transform: scale(1.2);
  color: red;
}

/* Quadrant-specific colors */
.quadrant-important-urgent {
  background: linear-gradient(135deg, #ff4e4e, #ff704d); /* Rouge Mario + lave */
  color: white;
}

.quadrant-important-not-urgent {
  background: linear-gradient(135deg, #6abe30, #4caf50); /* Vert tuyaux Mario */
  color: white;
}

.quadrant-not-important-urgent {
  background: linear-gradient(135deg, #ffcc33, #ffd966); /* Jaune pièces */
  color: black;
}

.quadrant-not-important-not-urgent {
  background: linear-gradient(135deg, #87cefa, #6ab5f5); /* Bleu ciel Mario */
  color: black;
}

/* Quadrant Header Styling */
.quadrant h3 {
  font-size: 1.8rem;
  font-family: 'Raleway', sans-serif;
  text-align: center;
  margin: 0;
  margin-top: -1rem; /* Push the title upwards */
  margin-bottom: 1rem; /* Adjust spacing below the title */
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
}

/* Task Item Styling */
.task-item {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.2s ease;
}

.task-item.completed span {
  text-decoration: line-through;
  text-decoration-color: red;
  text-decoration-thickness: 2px;
}

.task-item.completed .task-delete-button {
  text-decoration: none; /* Prevents line-through on the trash button */
  opacity: 1; /* Ensures trash icon remains fully visible */
}

.task-item.completed span {
  text-decoration: line-through;
  text-decoration-color: red; /* Strikethrough with color */
  text-decoration-thickness: 2px;
}

.task-item button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-left: 10px;
  transition: transform 0.2s;
}

.task-item button:hover {
  transform: scale(1.2);
}

/* Pipe Styling */
.pipe-container {
  position: absolute;
  top: -12rem; /* Adjust pipe position */
  left: 50%;
  z-index: 5; /* Ensure it appears above other elements */
  cursor: pointer; /* Make it clickable */
}

/* Pipe Styling */
.pipe-image {
  position: absolute;
  top: -9rem; /* Position it at the top of the page */
  left: -50px; /* Center it horizontally */
  width: 150px; /* Adjust width */
  height: auto;
  cursor: pointer;
  transition: transform 0.3s ease, filter 0.3s ease;
  z-index: 10; /* Ensure it's above other elements */
}
.pipe-image:hover {
  transform: scale(1.1) ; /* Slight zoom and rotation */
  filter: brightness(1.2) drop-shadow(0 0 10px rgba(0, 255, 0, 0.7)); /* Bright glow effect */
}



.motivational-text {
  font-size: 1rem;
  color: #6c757d;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.red-border {
  border: 4px solid red; /* Emphasize the active quadrant */
  border-radius: 10px;
  transition: border-color 0.3s ease;
}

.red-border .quadrant-header {
  color: red; /* Highlight the header text when active */
  font-weight: bold;
}

.pipe-container {
  position: absolute;
  top: -25px; /* Adjust based on your layout */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.pipe-image {
  width: 150px;
  cursor: pointer;
  transition: transform 0.3s, filter 0.3s;
}

